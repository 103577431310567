import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  InputBase,
  Drawer,
  Grid,
  Avatar,
  MenuItem,
  Box,
  Container,
  List,
  Typography,
  fade,
  Menu,
  Grow,
  Paper,
  Popper,
  MenuList,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Logo from "./../../component/Logo";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ListItem } from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { useWeb3React } from "@web3-react/core";
import { AuthContext } from "src/context/Auth";
import Dialog from "@material-ui/core/Dialog";
import { ACTIVE_NETWORK } from "src/constants";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { GiCancel } from "react-icons/gi";
import axios from "axios";
import Apiconfigs from "../../../src/config/ApiConfig";
import SearchResults from "./SearchResults";

const headersData = [
  // {
  //   label: "Explore",
  //   href: "/",
  // },
  // {
  //   label: "My items",
  //   href: "/user",
  // },
  // {
  //   label: "Following",
  //   href: "/following",
  // },
  // {
  //   label: "Activity",
  //   href: "/activity",
  // },
  // {
  //   label: "How it works",
  //   href: "/faq",
  // },
];

const useStyles = makeStyles((theme) => ({
  menuButton: {
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: "400",
    borderRadius: 0,
    minWidth: "auto",
    color: "#757575",
    height: "31px",
    padding: "0px 7px",
    letterSpacing: "1px",
    "@media (max-width: 900px)": {
      fontStyle: "normal",
      letterSpacing: "-0.6px",
      lineHeight: "24px",
      color: "#FFF",
      padding: "15px !important",
      height: "51px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    "&:active": {
      color: "#1ed760",
    },
    "&:last-child": {
      backgroundColor: "#1ed760",
    },
  },
  menuButton1: {
    width: "100%",
  },
  login: {
    backgroundColor: "#0D8CCD",
    marginLeft: "10px",
  },
  loginButton: {
    height: "35px",
    width: "35px",
  },
  toolbar: {
    display: "flex",
    padding: "10px 0",
    justifyContent: "space-between",
    height: "100%",
    "@media (max-width: 900px)": {
      paddingLeft: "75px",
      paddingRight: "20px",
      height: "100%",
    },
  },
  maindrawer: {
    height: "100%",
    background: "#0c0731",
    width: "260px",
  },
  logoDrawer: {
    paddingLeft: "10px",
    width: "140px",
    marginBottom: "30px",
  },
  drawerContainer: {
    padding: "20px 0px ",
    height: "100%",
    width: "260px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  drawericon: {
    color: "#000",
    position: "absolute",
    top: "0px",
    right: "-10px",
    fontSize: "25px",
  },
  logoImg: {
    width: "75px",
    // height: '44.5px',
    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      width: "52px",
    },
  },
  menuMobile: {
    fontSize: "16px",
    fontWeight: "400",
    fontStyle: "normal",
    letterSpacing: "-0.6px",
    lineHeight: "1.75",
    borderBottom: "1px solid #3e3e3e",
    padding: "16px",
    "@media (max-width: 500px)": {
      padding: "7px 0",
      width: "100%",
    },
  },
  paper1: {
    background: "black",
    color: "white",
  },
  containerHeight: {
    height: "100%",
  },
  mainHeader: {
    justifyContent: "space-between",
    padding: "0px",
  },
  search: {
    height: "31px",
    position: "relative",
    color: "#ABABAB",
    "&:hover": {},
    marginLeft: 20,
    width: "240px",
    maxWidth: "257px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "315px",
    },
  },
  searchIcon: {
    fontSize: "16px",
    padding: theme.spacing(0, 2),
    color: "#000000",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    fontSize: "16px",
    width: "100%",
  },
  wallet: {
    fontSize: "14px",
    fontWeight: "400",
    fontStyle: "normal",
    lineHeight: "21px",
    color: "#0D8CCD",
    border: "1px solid #0D8CCD",
    padding: "0 15px",
    borderRadius: "50px",
    height: "31px",
    "@media (max-width: 900px)": {
      marginLeft: "12px",
      marginTop: "12px",
    },
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    fontSize: "13px",
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100px",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "100%",
      },
    },
  },
  navbarMain: {
    boxShadow: "0 4px 8px rgb(0 0 0 / 12%)",
    height: 80,
    marginLeft: 40,
    padding: "0 25px",
    borderRadius: "15px",
  },
  submenu: {
    borderTop: "3px solid #0D8CCD",
    top: "20px !important",
  },
  dialogBox: {
    padding: "30px",
  },
  customizedButton: {
    position: "absolute",
    top: "-42px",
    right: "-9px",
    color: "#fff",
  },
  paper: {
    overflowY: "unset",
  },
  notificationIcon: {
    background: "red",
    marginLeft: "-13px",
    marginTop: "-10px",
    width: "12px",
    height: "12px",
    borderRadius: "50%",
    fontSize: "10px",
    color: "#ca4a4a",
  },
}));

export default function Header() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const auth = useContext(AuthContext);
  const [searchText, setSearchText] = useState("");
  const location = useLocation();
  const searchTextRef = React.useRef(null);
  const { activate, account, chainId, deactivate, library } = useWeb3React();
  const classes = useStyles();
  useEffect(() => {
    searchTextRef.current.focus();

    if (
      location.pathname === "/Search" &&
      location.search &&
      location.search.slice(1, location.search.length)
    ) {
      let text = location.search.slice(1, location.search.length);
      setSearchText(text);
    }
  }, [location]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const {
    loginButton,
    menuMobile,
    menuButton,
    menuButton1,
    toolbar,
    drawerContainer,
    drawericon,
    login,
    logoDrawer,
    navbarMain,
    containerHeight,
    search,
    searchIcon,
    mainHeader,
    inputInput,
    inputRoot,
    wallet,
    submenu,
    notificationIcon,
  } = useStyles();
  const history = useHistory();

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    searchTextRef.current.focus();
    const setResponsiveness = () => {
      return window.innerWidth < 1220
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const [open1, setOpen1] = useState({ community: false, user: false });
  const [openNotify, setOpenNotify] = useState(false);
  const anchorRef = { community: useRef(null), user: useRef(null) };

  const handleToggle = (name) => {
    setOpen1({ ...open1, [name]: !open1[name] });
  };

  const handleClose2 = (event, name) => {
    if (
      anchorRef[name].current &&
      anchorRef[name].current.contains(event.target)
    ) {
      return;
    }

    setOpen1({ ...open1, [name]: false });
  };

  function handleListKeyDown(event, name) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen1({ ...open1, [name]: false });
    }
  }

  function logout() {
    localStorage.removeItem("creatturAccessToken");
    history.push("/loginPage");
  }
  const readNotificationhandler = async () => {
    try {
      const res = await axios.get(Apiconfigs.readNotification, {
        headers: {
          token: localStorage.getItem("creatturAccessToken"),
        },
      });
    } catch (error) {}
  };

  console.log("auth.userData0", auth.userData);
  const displayDesktop = () => {
    return (
      <Container maxWidth="lg" className="p-0">
        <Toolbar className={toolbar}>
          {femmecubatorLogo}
          <Grid
            container
            item
            direction="row"
            justify="space-between"
            alignItems="center"
            className={navbarMain}
            style={{ paddingLeft: "0px" }}
          >
            {" "}
            <div
              className={auth.searchItemDetails && "searchField customSearch"}
            >
              <div className={search}>
                <div className={searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  ref={searchTextRef}
                  autoFocus
                  value={searchText}
                  placeholder="Search by creator, collection or collectible"
                  classes={{
                    root: inputRoot,
                    input: inputInput,
                  }}
                  inputProps={{ "aria-label": "search" }}
                  onChange={(e) => {
                    setSearchText(e.target.value);

                    history.push({
                      pathname: "/Search",
                      search: e.target.value,
                    });
                  }}
                />
              </div>
              {auth.searchItemDetails && (
                <SearchResults history={history} user={auth} />
              )}
            </div>
            {getMenuButtons()}
            <Box display="flex" justifyContent="flex-start" alignItems="center">
              {/* <IconButton
                aria-label='delete'
                size='small'
                // className={login}
                ref={anchorRef.community}
                aria-controls={open1.community ? 'menu-list-grow' : undefined}
                aria-haspopup='true'
                onClick={() => handleToggle('community')}
                style={{ marginRight: '15px', position: 'relative' }}
              >
                {' '}
                <NotificationsNoneIcon
                  style={{ fontSize: '30px', color: '#3D3D3D' }}
                />
                <span className='notificationCount'>7</span>
              </IconButton> */}
              <Popper
                open={open1.community}
                anchorEl={anchorRef.community.current}
                role={undefined}
                transition
                disablePortal
                className={submenu}
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom" ? "center top" : "center bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener
                        onClickAway={(e) => handleClose2(e, "community")}
                      >
                        <Box className="notificationBox">
                          <Typography variant="h4">Notifications</Typography>
                          <Box className="notificationList" mt={3}>
                            <img src="images/notification/1.png " alt=" " />
                            <Box>
                              <Typography variant="h4">
                                Unicorn Cake Arts
                              </Typography>
                              <Typography variant="h5">
                                Sold for 0.025 ETH
                              </Typography>
                              <Typography variant="h6">14 hours ago</Typography>
                            </Box>
                          </Box>
                          <Box className="notificationList" mt={3}>
                            <img src="images/notification/1.png " alt=" " />
                            <Box>
                              <Typography variant="h4">
                                Unicorn Cake Arts
                              </Typography>
                              <Typography variant="h5">
                                Sold for 0.025 ETH
                              </Typography>
                              <Typography variant="h6">14 hours ago</Typography>
                            </Box>
                          </Box>
                          <Box
                            style={{
                              paddingTop: "20px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              variant="outlined"
                              color="primary"
                              href="#outlined-buttons"
                              component={Link}
                              to="/notification"
                            >
                              See all
                            </Button>
                          </Box>
                        </Box>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Box className="adminName">
                  {" "}
                  <Typography variant="h4">
                    {auth && auth.userData ? auth.userData.name : ""}
                  </Typography>
                  <Typography variant="body2">
                    {auth && auth.userData ? auth.userData.userType : ""}
                  </Typography>
                </Box>
                <IconButton
                  aria-label="delete"
                  size="small"
                  className={login}
                  ref={anchorRef.user}
                  aria-controls={open1.user ? "menu-list-grow" : undefined}
                  aria-haspopup="true"
                  onClick={() => handleToggle("user")}
                >
                  {" "}
                  <Avatar
                    className={loginButton}
                    src={
                      auth && auth.userData
                        ? auth.userData.profilePic
                        : "images/user1.png"
                    }
                  />
                </IconButton>
                <Box>
                  <IconButton
                    onClick={() => {
                      readNotificationhandler();
                      history.push("/notification");
                    }}
                  >
                    <NotificationsIcon />
                    {auth?.unreadCount > 0 && (
                      <Box className={notificationIcon}>0</Box>
                    )}
                  </IconButton>
                </Box>
              </Box>

              <Popper
                open={open1.user}
                anchorEl={anchorRef.user.current}
                role={undefined}
                transition
                disablePortal
                className={submenu}
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom" ? "center top" : "center bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener
                        onClickAway={(e) => handleClose2(e, "user")}
                      >
                        <MenuList
                          autoFocusItem={open1}
                          id="menu-list-grow"
                          onKeyDown={(e) => handleListKeyDown(e, "user")}
                        >
                          <MenuItem component={Link} to="/adminProfile">
                            My Profile
                          </MenuItem>
                          <MenuItem component={Link} onClick={logout}>
                            Logout
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Box>{" "}
            {/* <Button variant="contained" size="small" color="secondary" onClick={handleClickOpen}>
        Connect
      </Button> */}
          </Grid>
        </Toolbar>
      </Container>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            <img className={logoDrawer} src="images/logo.png" alt="" />
            <div style={{ display: "flex" }}>
              <IconButton
                aria-label="delete"
                size="small"
                className={login}
                component={Link}
                to="/profile"
              >
                {" "}
                <Avatar className={loginButton} src={"images/user1.png"} />
              </IconButton>
              <span style={{ marginTop: "8px" }}> Admin Profile</span>
            </div>

            {getDrawerChoices()}

            <IconButton
              aria-label="delete"
              size="small"
              // className={login}
              ref={anchorRef.community}
              aria-controls={open1.community ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              component={Link}
              to="/notification"
              style={{ marginTop: "15px", position: "relative" }}
            >
              {" "}
              <NotificationsNoneIcon
                style={{ fontSize: "30px", color: "#3D3D3D" }}
              />
              <span>Notification</span>
            </IconButton>
          </div>
        </Drawer>

        <div>{femmecubatorLogo}</div>
        <Grid container>
          <Grid item xs={10}>
            <div className={search}>
              <div className={searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: inputRoot,
                  input: inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
              />
            </div>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              className={drawericon}
              {...{
                edge: "start",
                color: "inherit",
                "aria-label": "menu",
                "aria-haspopup": "true",
                onClick: handleDrawerOpen,
              }}
            >
              <MenuIcon
                width="60px"
                height="60px"
                style={{ color: "#197ab3", fontSize: "30px" }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          <Button
            {...{
              key: label,
              color: "inherit",
              to: href,
              component: Link,
              className: menuButton1,
            }}
          >
            <MenuItem className={menuMobile}>{label}</MenuItem>
          </Button>
        </>
      );
    });
  };

  const femmecubatorLogo = (
    <Box>
      <Link to="/">
        <Logo className="logoImg" />
      </Link>
    </Box>
  );

  const getMenuButtons = () => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          <Button
            {...{
              key: label,
              color: "inherit",
              to: href,
              component: Link,
              className: menuButton,
            }}
          >
            {label}
          </Button>
        </>
      );
    });
  };

  return (
    <>
      <AppBar
        position={history.location.pathname !== "/" ? "relative" : "absolute"}
        // className={history.location.pathname !== "/" ? "InnerHeader" : "Header"}
        style={{ backgroundColor: "#fff", position: "fixed" }}
      >
        <Container
          maxWidth={history.location.pathname !== "/" ? "lg" : "fixed"}
          className={containerHeight}
        >
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>
      </AppBar>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose1}
      >
        <MenuItem>
          <Link to="/profile">My Profile</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/user">My Nft</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/resell-nft">Resell Nft</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/create-nft">Create NFT</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/notification">Notification</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/search">Search</Link>
        </MenuItem>
      </Menu>
      {account && (
        <Dialog
          open={chainId !== ACTIVE_NETWORK}
          className="walletdailog"
          // onClose={handleClose}
          // aria-labelledby="alert-dialog-title"
          // aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" className="dailogTitle">
            {"Network Error"}
          </DialogTitle>
          <DialogContent className="loginModal">
            <DialogContentText id="alert-dialog-description">
              <Box className="walletlist">
                <Typography>Please swich to RINKEBY TESTNET...</Typography>
              </Box>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
