import React, { useState, useEffect, useContext } from "react";
import Sale from "./Sale";
import Owned from "./Owned";
import Activity from "./Activity";
import Created from "./Created";
import Liked from "./Liked";
import Following from "src/component/Following";
import { Link } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { ImShare2 } from "react-icons/im";
import { FiMoreHorizontal } from "react-icons/fi";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { SiFacebook } from "react-icons/si";
import { FaTwitter } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { GrMail } from "react-icons/gr";
import DialogActions from "@material-ui/core/DialogActions";
import { GiCancel } from "react-icons/gi";
import {
  Grid,
  Box,
  IconButton,
  Container,
  Typography,
  makeStyles,
  Button,
} from "@material-ui/core";
import axios from "axios";
import apiConfig from "src/config/ApiConfig";
import { useLocation } from "react-router-dom";
import PageLoading from "src/component/PageLoading";
import { sortAddress } from "src/utils";
// import { SocialLinkBox } from '../Profile/Profile';
import { UserContext } from "src/context/User";
import { AuthContext } from "src/context/Auth";
import { toast } from "react-toastify";
import {
  FacebookShareButton,
  TelegramShareButton,
  EmailShareButton,
  TwitterShareButton,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FiCopy } from "react-icons/fi";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  customizedButton: {
    position: "absolute",
    top: "-42px",
    right: "-9px",
    color: "#fff",
  },
  paper: {
    overflowY: "unset",
  },
  wallet: {
    background: "#fff",
    paddingTop: "68px",
    paddingBottom: theme.spacing(6),
  },
  sectionTitleHead: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    margin: "10px 0 ",
    padding: "0 0",
    width: "100%",
  },
  sectionTitleHead2: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    margin: "10px 0 ",
    padding: "0 0",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  metamask: {
    maxWidth: "100%",
    width: "400px",
  },
  selectOption: {
    width: "300px",
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "row",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    justifyContent: "space-around",
  },
  walletSet: {
    padding: "0",
  },
  qrCoce: {
    maxWidth: "100%",
    width: "250px",
  },

  copyQr: {
    maxWidth: "250px",
    display: "flex",
  },
  padding0: {
    padding: "0",
  },
  walletDetails: {
    display: "flex",
    justifyContent: "flex-start",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  FollowingBox: {
    height: "300px",
    overflowx: "scroll",
  },
}));

function myFunction() {
  var copyText = document.getElementById("myInput");
  copyText.select();
  copyText.setSelectionRange(0, 99999); /* For mobile devices */
  navigator.clipboard.writeText(copyText.value);
  toast.info(`Copied ${copyText.value}`);
}
export default function UsersView() {
  const auth = useContext(AuthContext);
  const profile = auth.userData;
  const classes = useStyles();
  const [tabview, setTabView] = useState("sale");
  const location = useLocation();
  const [followingOpen, setFollowingOpen] = useState(false);
  const [followersOpen, setFollowersOpen] = useState(false);
  const [isActive, setActive] = useState(false);
  const [isActive1, setActive1] = useState(false);
  const [userProfile, setUserProfile] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [onSaleList, setonSaleList] = useState([]);
  const [OwnedList, setOwnedList] = useState([]);
  const [createdList, setCreatedList] = useState([]);
  const [isFollowing, setIsFollowing] = useState(false);
  const [followingList, setFollowingList] = useState([]);
  const [followersList, setFollowersList] = useState([]);
  const [likesList, setLikesList] = useState([]);
  const [userID, setUserID] = useState("");
  const [soldNFTList, setSoldNFTList] = useState([]);
  const [maxPages, setMaxPages] = useState(1);
  const [allListPageNumber, setAllListPageNumber] = useState(1);
  const [activityList, setActivityList] = useState([]);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("All");

  const toggleClass = () => {
    setActive(!isActive);
  };
  const toggleClass1 = () => {
    setActive1(!isActive1);
  };
  const handleClose = () => {
    setFollowersOpen(false);
  };

  const getProfile = async (id, cancelTokenSource) => {
    try {
      setUserProfile();
      const res = await axios.get(`${apiConfig.profileUser}/${id}`, {
        cancelToken: cancelTokenSource && cancelTokenSource.token,
      });
      if (res.data.statusCode === 200) {
        setUserProfile(res.data.result);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log("ERROR", error);
    }
  };

  const getOnSaleDetails = async (id, cancelTokenSource) => {
    setonSaleList([]);

    try {
      const res = await axios.get(`${apiConfig.userOnSaleCount}/${id}`, {
        cancelToken: cancelTokenSource && cancelTokenSource.token,
      });
      if (res.data.statusCode === 200) {
        if (res.data.result.docs) {
          setonSaleList(res.data.result.docs);
        } else {
          setonSaleList([]);
        }
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const getOwenedDetails = async (id, cancelTokenSource) => {
    setOwnedList([]);
    try {
      const res = await axios.get(`${apiConfig.userOwendCount}/${id}`, {
        cancelToken: cancelTokenSource && cancelTokenSource.token,
      });
      if (res.data.statusCode === 200) {
        if (res.data.result.docs) {
          setOwnedList(res.data.result.docs);
        }
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const getCreatedDetails = async (id, cancelTokenSource) => {
    setCreatedList([]);
    try {
      const res = await axios.get(`${apiConfig.userCreatedCount}/${id}`, {
        cancelToken: cancelTokenSource && cancelTokenSource.token,
      });
      if (res.data.statusCode === 200) {
        if (res.data.result.docs) {
          setCreatedList(res.data.result.docs);
        } else {
          setCreatedList([]);
        }
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const getFollowingDetails = async (id, cancelTokenSource) => {
    setFollowingList([]);
    try {
      const res = await axios.get(`${apiConfig.userFollowingCount}/${id}`, {
        cancelToken: cancelTokenSource && cancelTokenSource.token,
      });
      if (res.data.statusCode === 200) {
        setFollowingList(res.data.result.following);
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const getFollowersDetails = async (id, cancelTokenSource) => {
    try {
      const res = await axios.get(`${apiConfig.userFollowerCount}/${id}`, {
        cancelToken: cancelTokenSource && cancelTokenSource.token,
      });
      if (res.data.statusCode === 200) {
        setFollowersList(res.data.result.followers);
        console.log("profile", profile);
        if (profile) {
          const resArr = res.data.result.followers.filter(
            (data) => data.followersId._id === profile._id
          );
          setIsFollowing(resArr.length > 0 ? true : false);
        }
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const getLikesDetails = async (id, cancelTokenSource) => {
    console.log("id", id);
    setLikesList([]);
    try {
      const res = await axios.get(`${apiConfig.userLikesCount}/${id}`, {
        cancelToken: cancelTokenSource && cancelTokenSource.token,
      });
      if (res.data.statusCode === 200) {
        if (res.data.result.docs) {
          const result = res.data.result.docs.filter((data) => data.orderId);
          setLikesList(result);
        }
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const getActivityDetails = async (id, isLoadmore, cancelTokenSource) => {
    let page = isLoadmore ? allListPageNumber + 1 : allListPageNumber;
    setAllListPageNumber(page);
    let allList = [];
    setDataToDisplay([]);
    setActivityList([]);
    for (let i = 1; i <= page; i++) {
      try {
        const res = await axios.get(`${apiConfig.showActivity}/${id}`, {
          params: {
            page: i,
            limit: 10,
          },
          cancelToken: cancelTokenSource && cancelTokenSource.token,
        });
        if (res.data.statusCode === 200) {
          if (res.data.result.docs) {
            setMaxPages(res.data.result.pages);
            allList = await allList.concat(res.data.result.docs);
            const filterData = allList.filter(
              (data) => data.nftId || data.followingUserId
            );
            setActivityList(filterData);
            setDataToDisplay(filterData);
          } else {
            setActivityList([]);
          }
        }
      } catch (error) {
        console.log("ERROR", error);
      }
    }
  };

  const followUnfollowDandler = async (id) => {
    try {
      const token = localStorage.getItem("creatturAccessToken");
      const res = await axios.post(
        apiConfig.following,
        {
          userId: id,
        },
        {
          headers: {
            token,
          },
        }
      );
      console.log("res", res);
      if (res.data.statusCode === 200) {
        toast.success(res.data.responseMessage);

        if (profile) {
          const resArr = res.data.result.following.filter(
            (data) => data.followingId === id
          );
          setIsFollowing(resArr.length > 0 ? true : false);
          auth.getProfileHandler(token);
        }
        getFollowingDetails(userID);
        getFollowersDetails(userID);
      } else {
        toast.error(res.data.responseMessage);
      }
    } catch (error) {
      toast.error(error.message);

      console.log("ERROR", error);
    }
  };

  const blockUnblockUserHandler = async (id) => {
    try {
      const token = localStorage.getItem("creatturAccessToken");
      const res = await axios.put(
        apiConfig.blockUnblockUser,
        {
          _id: id,
        },
        {
          headers: {
            token,
          },
        }
      );
      console.log("res", res);
      if (res.data.statusCode === 200) {
        getProfile(userID);
        toast.success(res.data.responseMessage);
      } else {
        toast.error(res.data.responseMessage);
      }
    } catch (error) {
      toast.error(error.message);

      console.log("ERROR", error);
    }
  };

  const getSoldItemListDetails = async (id, cancelTokenSource) => {
    try {
      const res = await axios.get(`${apiConfig.nftSoldCount}/${id}`, {
        cancelToken: cancelTokenSource && cancelTokenSource.token,
      });
      if (res.data.statusCode === 200) {
        if (res.data.result.docs) {
          setSoldNFTList(res.data.result.docs);
        }
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  useEffect(() => {
    if (selectedFilter === "All") {
      setDataToDisplay(activityList);
    }
    if (selectedFilter === "Listing") {
      const result = activityList.filter((data) => data.type === "NEWNFT");
      setDataToDisplay(result);
    }
    if (selectedFilter === "Likes") {
      const result = activityList.filter(
        (data) => data.type === "DISLIKE" || data.type === "LIKE"
      );
      setDataToDisplay(result);
    }
    if (selectedFilter === "Bids") {
      const result = activityList.filter((data) => data.type === "BIDPLACE");
      setDataToDisplay(result);
    }
    if (selectedFilter === "Sold") {
      const result = activityList.filter((data) => data.type === "BIDPLACE");
      setDataToDisplay(result);
    }
    if (selectedFilter === "Purchases") {
      const result = activityList.filter((data) => data.type === "SALEORDER");
      setDataToDisplay(result);
    }
    if (selectedFilter === "Followings") {
      const result = activityList.filter(
        (data) => data.type === "UNFOLLOW" || data.type === "FOLLOW"
      );
      setDataToDisplay(result);
    }
  }, [selectedFilter, activityList]);

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();
    console.log("auth.userLoggedIn", auth.userLoggedIn);
    if (
      auth.userLoggedIn &&
      location.search &&
      location.search.slice(1, location.search.length)
    ) {
      const id = location.search.slice(1, location.search.length);
      setUserID(id);
      getProfile(id, cancelTokenSource);
      getOnSaleDetails(id, cancelTokenSource);
      getOwenedDetails(id, cancelTokenSource);
      getCreatedDetails(id, cancelTokenSource);
      getFollowingDetails(id, cancelTokenSource);
      getFollowersDetails(id, cancelTokenSource);
      getLikesDetails(id, cancelTokenSource);
      getActivityDetails(id, false, cancelTokenSource);
      getSoldItemListDetails(id, cancelTokenSource);
    }
    return () => {
      cancelTokenSource.cancel();
    };
  }, [location, profile, auth.userLoggedIn]);

  const updateAllDetails = async () => {
    if (userID) {
      getProfile(userID);
      getOnSaleDetails(userID);
      getOwenedDetails(userID);
      getCreatedDetails(userID);
      getFollowingDetails(userID);
      getFollowersDetails(userID);
      getLikesDetails(userID);
      getActivityDetails(userID);
      getSoldItemListDetails(userID);
    }
  };
  console.log(userProfile, "userProfile>>>>>>>>..");
  return (
    <>
      {isLoading && !userProfile ? (
        <PageLoading />
      ) : (
        <Box className={classes.wallet}>
          {" "}
          <Box className="usersView">
            <Container maxWidth="lg" style={{ marginTop: "-80px" }}>
              <figure className="figure">
                <img
                  src={
                    userProfile?.bannerImage
                      ? userProfile?.bannerImage
                      : "/images/Profilebg.png"
                  }
                  className="img_responsive"
                  alt=""
                />
                <Box className="share_Box ">
                  <Box>
                    <Box
                      className={
                        isActive ? "toggel_box show" : "toggel_box hide"
                      }
                    >
                      Share link to this page...
                      <SocialLinkBox />{" "}
                    </Box>
                    <Button className="share-btn" onClick={toggleClass}>
                      <ImShare2 />
                    </Button>
                  </Box>
                  {/* <Box>
                    <Box
                      className={
                        isActive1 ? 'toggel_box show' : 'toggel_box hide'
                      }
                    >
                      Report Page
                    </Box>
                    <Button className='share-btn' onClick={toggleClass1}>
                      <FiMoreHorizontal />
                    </Button>
                  </Box> */}
                </Box>
              </figure>
            </Container>
            <Container maxWidth="lg">
              <Box className="usersPic">
                <figure>
                  <img
                    src={
                      userProfile && userProfile.profilePic
                        ? userProfile.profilePic
                        : "/images/users/5.png"
                    }
                    className="img_responsive "
                    style={{ borderRadius: "50%" }}
                    alt=""
                  />
                  {userProfile && userProfile.accountVerify && (
                    <img
                      src="images/collections/check.png"
                      className="check_icon"
                    />
                  )}
                </figure>

                <Box className="userDetails" textAlign="center">
                  {userProfile && userProfile.name && (
                    <Typography variant="h4" className="SectionTitle m-b-5">
                      {userProfile?.name}
                    </Typography>
                  )}
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mb={4}
                  >
                    <input
                      id={"myInput"}
                      value={userProfile?.walletAddress}
                      style={{ display: "none" }}
                    />
                    <Tooltip title="Address" placement="top">
                      <Typography variant="h5" className="tokenAddress">
                        {sortAddress(userProfile?.walletAddress)}
                        <CopyToClipboard text={userProfile?.walletAddress}>
                          <FiCopy onClick={() => myFunction()} />
                        </CopyToClipboard>{" "}
                      </Typography>
                    </Tooltip>
                  </Box>
                  {profile && profile._id === userID ? (
                    <Button
                      variant="contained"
                      color="primary"
                      component={Link}
                      to="/edit-profile"
                    >
                      Edit Profile
                    </Button>
                  ) : (
                    <Button
                      onClick={() => followUnfollowDandler(userID)}
                      variant="contained"
                      color="primary"
                      disabled={userProfile && userProfile.status === "BLOCK"}
                    >
                      {isFollowing ? "Unfollow" : "Follow"}
                    </Button>
                  )}
                  <Button
                    onClick={() => blockUnblockUserHandler(userID)}
                    variant="contained"
                    color="primary"
                  >
                    {userProfile && userProfile.status === "BLOCK"
                      ? "Unblock"
                      : "BLOCK"}
                  </Button>
                  <Typography
                    variant="body2"
                    color="primary"
                    style={{ marginTop: 20 }}
                  >
                    {profile && profile.bio}
                  </Typography>
                  <Box className="socialLink" mt={4}>
                    {profile && profile.twitterUsername && (
                      <a href={profile.twitterUsername} target="_blank">
                        <FaTwitter style={{ color: "#0D8CCD " }} />
                        <Typography variant="body">
                          {profile.twitterUsername}
                        </Typography>
                      </a>
                    )}
                    {profile && profile.customUrl && (
                      <a href={profile.customUrl} target="_blank">
                        <img src="images/website.png" />
                        <Typography variant="body">
                          {profile.customUrl}
                        </Typography>
                      </a>
                    )}

                    {/* <Link>
                      <img src='images/insta.png' />
                      <Typography variant='body'>4bullsG</Typography>
                    </Link> */}
                  </Box>
                </Box>
              </Box>
            </Container>
          </Box>
          {/* featured */}
          <Box mt={4} mb={2}>
            <Container maxWidth="lg">
              <Box className="TabButtonsBox">
                <Button
                  className={tabview === "sale" ? "active" : " "}
                  onClick={() => setTabView("sale")}
                >
                  On sale <span>{onSaleList.length}</span>
                </Button>
                <Button
                  className={tabview === "Owned" ? "active" : " "}
                  onClick={() => setTabView("Owned")}
                >
                  Owned <span>{OwnedList.length}</span>
                </Button>
                <Button
                  className={tabview === "Sold" ? "active" : " "}
                  onClick={() => setTabView("Sold")}
                >
                  Sold <span>{soldNFTList.length}</span>
                </Button>
                <Button
                  className={tabview === "Created" ? "active" : " "}
                  onClick={() => setTabView("Created")}
                >
                  Created <span>{createdList.length}</span>
                </Button>
                <Button
                  className={tabview === "Liked" ? "active" : " "}
                  onClick={() => setTabView("Liked")}
                >
                  Liked <span>{likesList.length}</span>
                </Button>
                <Button
                  className={tabview === "Activity" ? "active" : " "}
                  onClick={() => setTabView("Activity")}
                >
                  Activity{" "}
                </Button>
                <Button onClick={() => setFollowingOpen(true)}>
                  Following <span>{followingList.length}</span>
                </Button>
                <Button onClick={() => setFollowersOpen(true)}>
                  Followers <span>{followersList.length}</span>
                </Button>
              </Box>
              <Box className="TabButtonsContant">
                {tabview === "sale" ? (
                  <Sale
                    orderList={onSaleList}
                    userID={userID}
                    callBackFun={(id) => updateAllDetails(id)}
                  />
                ) : (
                  ""
                )}
                {tabview === "Owned" ? (
                  <Owned
                    OwnedList={OwnedList}
                    userID={userID}
                    callBackFun={(id) => updateAllDetails(id)}
                  />
                ) : (
                  ""
                )}
                {tabview === "Sold" ? (
                  <Owned
                    OwnedList={soldNFTList}
                    userID={userID}
                    callBackFun={(id) => updateAllDetails(id)}
                  />
                ) : (
                  ""
                )}
                {tabview === "Created" ? (
                  <Created
                    createdList={createdList}
                    userID={userID}
                    callBackFun={(id) => updateAllDetails(id)}
                  />
                ) : (
                  ""
                )}
                {tabview === "Liked" ? (
                  <Liked
                    likesList={likesList}
                    userID={userID}
                    callBackFun={(id) => updateAllDetails(id)}
                  />
                ) : (
                  ""
                )}
                {tabview === "Activity" ? (
                  <Activity
                    activityList={dataToDisplay}
                    userID={userID}
                    callBackFun={(id, status) => getActivityDetails(id, status)}
                    selectedFilter={selectedFilter}
                    setSelectedFilter={(data) => setSelectedFilter(data)}
                    allListPageNumber={allListPageNumber}
                    maxPages={maxPages}
                  />
                ) : (
                  ""
                )}
              </Box>
            </Container>
          </Box>
          {followingOpen && (
            <Dialog
              open={followingOpen}
              onClose={() => setFollowingOpen(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              classes={{ paper: classes.paper }}
            >
              <DialogActions>
                <IconButton
                  onClick={() => setFollowingOpen(false)}
                  className={classes.customizedButton}
                >
                  <GiCancel />
                </IconButton>
              </DialogActions>
              <DialogContent className={classes.padding0}>
                <Box align="center" mb={5}>
                  <img src="images/Following.svg" />
                  <Typography variant="h5">Following</Typography>
                </Box>
                <Box className={classes.FollowingBox}>
                  <Grid container>
                    {followingList.map((data, i) => {
                      let reqRes = [];
                      if (profile) {
                        reqRes = profile.following.filter(
                          (userD) => userD.followingId === data.followingId._id
                        );
                      }
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          key={i}
                          className="walletSet "
                        >
                          <Following
                            data={data.followingId}
                            type="timing"
                            index={i}
                            user={auth}
                            callbackFn={(id) => followUnfollowDandler(id)}
                            isFollow={reqRes.length > 0 ? false : true}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              </DialogContent>
            </Dialog>
          )}
          {followersOpen && (
            <Dialog
              open={followersOpen}
              onClose={() => setFollowersOpen(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              classes={{ paper: classes.paper }}
            >
              <DialogActions>
                <IconButton
                  onClick={() => setFollowersOpen(false)}
                  className={classes.customizedButton}
                >
                  <GiCancel />
                </IconButton>
              </DialogActions>
              <DialogContent className={classes.padding0}>
                <Box align="center" mb={5}>
                  <img src="images/Following.svg" />
                  <Typography variant="h5">Followers</Typography>
                </Box>
                <Box className={classes.FollowingBox}>
                  <Grid container>
                    {followersList.map((data, i) => {
                      let reqRes = [];
                      if (profile) {
                        reqRes = profile.followers.filter(
                          (userD) => userD.followersId === data.followersId._id
                        );
                      }
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          key={i}
                          className="walletSet "
                        >
                          <Following
                            data={data.followersId}
                            type="timing"
                            index={i}
                            user={auth}
                            callbackFn={(id) => followUnfollowDandler(id)}
                            isFollow={reqRes.length > 0 ? false : true}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              </DialogContent>
            </Dialog>
          )}
        </Box>
      )}
    </>
  );
}

export const SocialLinkBox = ({ data }) => {
  const classes = useStyles();
  return (
    <List>
      <ListItem>
        <label>
          <FacebookShareButton
            url={"https://frontend-nft.mobiloitte.org/"}
            quote={data?.nftId?.tokenName}
            hashtag={data?.nftId?.tokenName}
          >
            {/* <FacebookIcon size={36} round={true} /> */}
            <SiFacebook />
          </FacebookShareButton>
        </label>
        <Typography variant="h6">Facebook</Typography>
      </ListItem>
      <ListItem>
        <label>
          <TwitterShareButton
            url={"https://frontend-nft.mobiloitte.org/"}
            quote={data?.nftId?.tokenName}
            hashtag={data?.nftId?.tokenName}
          >
            {/* <FacebookIcon size={36} round={true} /> */}
            <FaTwitter />
          </TwitterShareButton>
        </label>
        <Typography variant="h6">Twitter</Typography>
      </ListItem>
      <ListItem>
        <label>
          <TelegramShareButton
            url={"https://frontend-nft.mobiloitte.org/"}
            title={data?.nftId?.tokenName}
            hashtag={data?.nftId?.tokenName}
          >
            <FaTelegramPlane />
          </TelegramShareButton>
        </label>
        <Typography variant="h6">Telegram</Typography>
      </ListItem>
      <ListItem>
        <label>
          <EmailShareButton
            url={"https://frontend-nft.mobiloitte.org/"}
            quote={data?.nftId?.tokenName}
            subject="frontend"
            body="frontend frontend"
            hashtag={data?.nftId?.tokenName}
          >
            <GrMail />
          </EmailShareButton>
        </label>
        <Typography variant="h6">E-mail</Typography>
      </ListItem>
    </List>
  );
};
