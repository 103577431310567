import React from "react";

import { Typography } from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import BusinessIcon from "@material-ui/icons/Business";
import TableChartIcon from "@material-ui/icons/TableChart";
import TwitterIcon from "@material-ui/icons/Twitter";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { FiLinkedin } from "react-icons/fi";
import YouTubeIcon from "@material-ui/icons/YouTube";

const useStyles = makeStyles((theme) => ({
  footerr: {
    width: "100%",
    height: "320px",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#0d8ccd",
  },
  fungy: {
    width: "126px",
    height: "38px",
    marginTop: "50px",
  },
}));
export default function Footer() {
  const classes = useStyles();
  return (
    <div className={classes.footerr}>
      <div className="img-fungy">
        <img className={classes.fungy} src="/images/fungy.png" />
      </div>
      <div className="text-lorem">
        <p style={{ color: "white" }}>
          Lorem ipsum dolor sit amet, consectetur adipiscing <br />
          elit, sed do eiusmod tempor incididunt.
        </p>
      </div>
      <Typography style={{ marginTop: "30px" }}>
        <Link
          href="https://www.facebook.com/"
          target="_blank"
          color="secondary"
        >
          <FacebookIcon style={{ color: "white" }} />
        </Link>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        <BusinessIcon style={{ color: "white" }} />
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        <Link href="https://in.linkedin.com/" color="secondary" target="_blank">
          <FiLinkedin style={{ color: "white" }} />
        </Link>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        <Link
          href="https://twitter.com/fungynft"
          color="secondary"
          target="_blank"
        >
          <TwitterIcon style={{ color: "white" }} />
        </Link>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        <Link
          href="https://www.youtube.com/channel/"
          color="secondary"
          target="_blank"
        >
          <YouTubeIcon style={{ color: "white" }} />
        </Link>
      </Typography>
      <p style={{ color: "white", marginTop: "30px" }}>
        Copyright © 2021. Fungy. All rights reserved.
      </p>
    </div>
  );
}
