import { Box, Typography } from "@material-ui/core";
import React from "react";

export default function SearchResults({ user, history }) {
  return (
    <ul className="list-group text-dark" id="search-list">
      {user.searchItemDetails?.collectionList?.length > 0 && (
        <>
          <li
            className="list-group-item"
            style={{ textAlign: "left", zIndex: 999 }}
          >
            <Box display={"flex"} justifyContent="space-between">
              <Box display={"flex"}>
                <Typography variant="h6">Collections</Typography>
              </Box>
            </Box>
          </li>
          {user.searchItemDetails.collectionList.map((data, i) => {
            return (
              <li
                key={i}
                className="list-group-item"
                style={{ textAlign: "left", zIndex: 999 }}
              >
                <Box display={"flex"} justifyContent="space-between">
                  <Box display={"flex"}>
                    <img src={data.collectionImage} alt="" />
                    <Typography> {data.name}</Typography>
                  </Box>
                </Box>
              </li>
            );
          })}
        </>
      )}

      {user.searchItemDetails?.userList?.length > 0 && (
        <>
          <li
            className="list-group-item"
            style={{ textAlign: "left", zIndex: 999 }}
          >
            <Box display={"flex"} justifyContent="space-between">
              <Box display={"flex"}>
                <Typography variant="h6">User's</Typography>
              </Box>
            </Box>
          </li>
          {user.searchItemDetails.userList.map((data, i) => {
            return (
              <li
                key={i}
                className="list-group-item"
                style={{ textAlign: "left", zIndex: 999 }}
                onClick={() => {
                  history.push({
                    pathname: "/user",
                    search: data._id,
                  });
                }}
              >
                <Box display={"flex"} justifyContent="space-between">
                  <Box display={"flex"}>
                    <img
                      src={
                        data.profilePic ? data.profilePic : "images/token/1.png"
                      }
                      alt=""
                    />
                    <Typography> {data.name}</Typography>
                  </Box>
                </Box>
              </li>
            );
          })}
        </>
      )}
      {user.searchItemDetails?.orderList?.length > 0 && (
        <>
          <li
            className="list-group-item"
            style={{ textAlign: "left", zIndex: 999 }}
          >
            <Box display={"flex"} justifyContent="space-between">
              <Box display={"flex"}>
                <Typography variant="h6">NFT's</Typography>
              </Box>
            </Box>
          </li>
          {user.searchItemDetails.orderList.map((data, i) => {
            return (
              <li
                key={i}
                className="list-group-item"
                style={{ textAlign: "left", zIndex: 999 }}
                onClick={() => {
                  history.push({
                    pathname: "/detailsnftCard",
                    search: data._id,
                  });
                }}
              >
                <Box display={"flex"} justifyContent="space-between">
                  <Box display={"flex"}>
                    <img src={data.nftId.uploadCover} alt="" />
                    <Typography> {data.nftId.tokenName}</Typography>
                  </Box>
                </Box>
              </li>
            );
          })}
        </>
      )}
    </ul>
  );
}
