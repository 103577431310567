import React, { useState } from "react";

import {
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  makeStyles,
} from "@material-ui/core";
import { GiCancel } from "react-icons/gi";
import axios from "axios";
import apiConfig from "src/config/ApiConfig";
import ButtonCircularProgress from "./ButtonCircularProgress";
import { SocialLinkBox } from "src/views/pages/UsersView/UsersView";

const useStyles = makeStyles((theme) => ({
  btnWidth: {
    width: "250px",
    maxWidth: "100%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: "0 !important",
      marginBottom: "10px !important",
    },
  },

  customizedButton: {
    position: "absolute",
    top: "-42px",
    right: "-9px",
    color: "#fff",
  },
  textfildBorder: {
    border: "1px solid #898989",
  },
}));

export default function Report({ opne, setclose, nftId, artist }) {
  const classes = useStyles();
  const [message, setMessage] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState();
  const postReport = async () => {
    setIsSubmit(true);
    setResponseMessage();
    if (message !== "") {
      try {
        setIsLoading(true);
        const token = localStorage.getItem("creatturAccessToken");
        const res = await axios.post(
          apiConfig.createReports,
          {
            nftId,
            artist,
            message,
          },
          {
            headers: {
              token,
            },
          }
        );
        setResponseMessage(res.data.responseMessage);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);

        console.log("ERROR", error);
      }
    }
  };

  return (
    <Dialog
      fullWidth="sm"
      maxWidth="sm"
      open={opne}
      onClose={() => setclose(false)}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogActions>
        <IconButton
          onClick={() => setclose(false)}
          className={classes.customizedButton}
        >
          <GiCancel />
        </IconButton>
      </DialogActions>
      <DialogContent>
        <Typography variant="h4">Why are you reporting?</Typography>
        <Typography variant="body2" component="span">
          Describe why you think this item should be removed from marketplace
        </Typography>

        <Box mt={2}>
          <label style={{ paddingTop: "10px" }}>Message</label>
          <TextField
            fullWidth
            type="text"
            variant="outlined"
            multiline
            rows={4}
            rowsMax={4}
            placeholder="Tell us some details"
            className={classes.textfildBorder}
            onChange={(e) => setMessage(e.target.value)}
            // error={isSubmit && message === ''}
          />
          {isSubmit && message === "" && (
            <Typography style={{ color: "red" }} variant="body2">
              {" "}
              Please enter message
            </Typography>
          )}
        </Box>
        {responseMessage !== "" && (
          <Box mt={2}>
            <Typography variant="body2">{responseMessage}</Typography>
          </Box>
        )}
        <Box align="center" className="modal_button_div" mt={4}>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={() => postReport()}
            className={classes.btnWidth}
            mb={2}
            disabled={isLoading}
          >
            REPORT {isLoading && <ButtonCircularProgress />}
          </Button>
          <Button
            variant="contained"
            size="large"
            onClick={() => setclose(false)}
            className={classes.btnWidth}
          >
            CANCEL
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export function ShareBox({ open, setClose, data }) {
  const classes = useStyles();

  return (
    <Dialog
      fullWidth="sm"
      maxWidth="sm"
      open={open}
      onClose={() => setClose(false)}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogActions>
        <IconButton
          onClick={() => setClose(false)}
          className={classes.customizedButton}
        >
          <GiCancel />
        </IconButton>
      </DialogActions>
      <DialogContent>
        <Box className="share_Box share_Box2 ">
          <Typography veriant="h4"> Share this NFT</Typography>
          <SocialLinkBox data={data} />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
