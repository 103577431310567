import React from 'react';
import { Typography, Box, makeStyles, Grid } from '@material-ui/core';
import UsersCard from 'src/component/UsersCard';
import { Link } from 'react-router-dom';

export default function Users(props) {
  const { type, data, searchUserList } = props;

  return (
    <Box>
      <Grid container>
        {searchUserList.map((data, i) => {
          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              key={i}
              className='walletSet mb-20'
            >
              <UsersCard data={data} type='timing' index={i} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
