import React from "react";
import {
  Typography,
  Box,
  makeStyles,
  Avatar,
  Grid,
  Button,
  Link,
} from "@material-ui/core";
import { FaEllipsisV } from "react-icons/fa";
import { FaHeart } from "react-icons/fa";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { BsClockHistory } from "react-icons/bs";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  NftImg: {
    borderRadius: 10,
    display: "block",
    miHeight: "300px",
    position: "relative",
  },
  bottomblock: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
  },
  bottomTop: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    margin: "10px 0 0",
  },
  playbutton: {
    position: "absolute",
    bottom: 5,
    right: 10,
  },
}));

export default function UsersCard(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const { type, data } = props;
  const classes = useStyles();

  return (
    <Box
      className="CardBox"
      onClick={() =>
        history.push({
          pathname: "/user",
          search: data._id,
        })
      }
    >
      <Box className="User_card">
        <figure class="user_img">
          <img
            class="rounded-circle"
            src={data.profilePic ? data.profilePic : "images/users/7.png"}
            alt=""
          />
          {data.accountVerify && (
            <img src={data.check} className="check_icon2" />
          )}
        </figure>
        <Box>
          <Typography variant="h5"> {data.name}</Typography>
          <Typography variant="body2">{data.topBuyerAmount}</Typography>
        </Box>
      </Box>
    </Box>
  );
}
