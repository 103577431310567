import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { axiosInstance } from "../utils";
import apiConfig from "src/config/ApiConfig";
import { useWeb3React } from "@web3-react/core";
import { injected } from "src/connectors";
import { socketURL } from "src/config/ApiConfig";

export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("creatturAccessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("creatturAccessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.localStorage.getItem("creatturAccessToken");
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData, setUserData] = useState({});
  const [allOrdersList, setallOrdersList] = useState([]);
  const [artists, setArtists] = useState([]);
  const [nfts, setNfts] = useState([]);
  const [allCollection, setAllCollection] = useState([]);
  const [allCategory, setAllCategory] = useState([]);
  const [notificationList, setNotificationList] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);

  const [allListPageNumber, setAllListPageNumber] = useState(1);
  const [maxPages, setMaxPages] = useState(1);
  const [promotedNftList, setPromotedNftList] = useState([]);
  const { activate, account, chainId, deactivate, library } = useWeb3React();
  const [blockNFTList, setBlockNFTList] = useState([]);
  const [searchItemDetails, setSearchItemDetails] = useState();

  // const getPlaceOrderList = async () => {
  //   axiosInstance({ method: 'GET', url: '/nft/allOrderList' })
  //     .then((res) => {
  //       if (res.data.result.docs) {
  //         const result = res.data.result.docs.filter(
  //           (data) => data.collectionId
  //         );
  //         setallOrdersList(result);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err.response?.data?.responseMessage);
  //     });
  // };

  const getPlaceOrderList = async (isLoadmore) => {
    let page = isLoadmore ? allListPageNumber + 1 : allListPageNumber;
    setAllListPageNumber(page);
    if (isLogin) {
      promotedListHandelr();
      getBlockNFTistHandler();
    }
    let allList = [];
    for (let i = 1; i <= page; i++) {
      try {
        const res = await axios.get(apiConfig.allOrderList, {
          params: {
            page: i,
          },
        });
        if (res.data.statusCode === 200) {
          if (res.data.result.docs) {
            setMaxPages(res.data.result.pages);
            allList = await allList.concat(res.data.result.docs);
            console.log("allList", allList);

            const result = allList.filter(
              (data) => data.price || data.startPrice
            );
            setallOrdersList(result);
          }
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  const getlistCollection = async () => {
    try {
      const res = await axios.get(apiConfig.listCollection);
      if (res.data.statusCode === 200) {
        if (res.data.result.docs) {
          setAllCollection(res.data.result.docs);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const getListCategory = async () => {
    try {
      const res = await axios.get(apiConfig.listCategory);
      if (res.data.statusCode === 200) {
        if (res.data.result.docs) {
          setAllCategory(res.data.result.docs);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getBlockNFTistHandler = async () => {
    const token = window.localStorage.getItem("creatturAccessToken");

    setBlockNFTList([]);
    try {
      const res = await axios.get(apiConfig.listOrder, {
        params: {
          // limit: 100,
          status: "BLOCK",
        },
        headers: {
          token,
        },
      });
      if (res.data.statusCode === 200) {
        if (res.data.result.docs) {
          setBlockNFTList(res.data.result.docs);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const promotedListHandelr = async () => {
    try {
      const token = window.localStorage.getItem("creatturAccessToken");

      const res = await axios.get(apiConfig.listPromoteOrder, {
        params: {
          limit: 100,
        },
        headers: {
          token,
        },
      });
      if (res.data.statusCode === 200) {
        if (res.data.result.docs) {
          setPromotedNftList(res.data.result.docs);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getPlaceOrderList();
    getlistCollection();
    // getBlockNFTistHandler();
    // promotedListHandelr();
  }, []);

  const getUserProfile = async () => {
    const token = window.localStorage.getItem("creatturAccessToken");

    try {
      const res = await axios.get(apiConfig.adminProfile, {
        headers: {
          token,
        },
      });
      console.log("res", res);
      if (res.data.statusCode === 200) {
        setUserData(res.data.result);
        setIsLogin(true);
        promotedListHandelr();
        getBlockNFTistHandler();
      } else {
        setIsLogin(false);
      }
    } catch (error) {
      setIsLogin(false);

      console.log("ERROR", error);
    }
  };

  const listArtistHandler = async (cancelTokenSource) => {
    const token = localStorage.getItem("creatturAccessToken");

    try {
      const res = await axios.get(apiConfig.listArtist, {
        cancelToken: cancelTokenSource && cancelTokenSource.token,
        headers: {
          token,
        },
      });
      if (res.data.statusCode === 200) {
        if (res.data.result.docs) {
          setArtists(res.data.result.docs);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();

    if (isLogin && userData._id) {
      listArtistHandler(cancelTokenSource);
    }
    return () => {
      cancelTokenSource.cancel();
    };
  }, [isLogin, userData]);

  useEffect(() => {
    if (isLogin) {
      axiosInstance({ method: "GET", url: "/admin/listNft" })
        .then((res) => {
          setNfts(res.data.result.docs);
        })
        .catch((err) => {
          console.log(err.response?.data?.responseMessage);
        });
    }
  }, []);

  const connectWalletHandler = () => {
    activate(injected, undefined, true).catch((error) => {
      if (error) {
        activate(injected);
      }
    });
  };

  useEffect(() => {
    if (isLogin) {
      getUserProfile();
    }
  }, [isLogin]);

  useEffect(() => {
    if (isLogin) {
      getListCategory();
    }
  }, [isLogin]);
  // console.log("adsadadadada");
  const [isloading, setIsloading] = useState(false);
  useEffect(() => {
    const web = new WebSocket(socketURL);
    const accessToken = localStorage.getItem("creatturAccessToken");

    setIsloading(true);
    if (accessToken) {
      try {
        web.onopen = () => {
          const dataToSend = {
            option: "notification",
            token: accessToken,
          };
          web.send(JSON.stringify(dataToSend));
          web.onmessage = async (event) => {
            if (event.data !== "[object Promise]" && event.data !== "null") {
              let obj = JSON.parse(event.data);
              if (obj.data && obj.data.length > 0) {
                setNotificationList(obj.data);
                setIsloading(false);
                setUnreadCount(obj.unReadCount);
              } else {
                setNotificationList([]);
                setUnreadCount(0);
                setIsloading(false);
              }
            }
          };
        };
        return () => {
          setNotificationList();
          setUnreadCount(0);
          web.close();
        };
      } catch (err) {}
    }
  }, []);

  let data = {
    userLoggedIn: isLogin,
    userData,
    allOrdersList,
    artists,
    nfts,
    allCollection,
    allCategory,
    maxPages,
    allListPageNumber,
    promotedNftList,
    blockNFTList,
    unreadCount,
    notificationList,
    isloading,
    searchItemDetails,
    setSearchItemDetails: (data) => setSearchItemDetails(data),
    getProfileHandler: () => getUserProfile(),
    getListCategory: () => getListCategory(),
    promotedListHandelr: () => promotedListHandelr(),
    connectWalletHandler: () => connectWalletHandler(),
    getPlaceOrderList: (data) => getPlaceOrderList(data),
    getBlockNFTistHandler: () => getBlockNFTistHandler(),
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
