import { baseApiUrl } from "../constants";
import { Contract } from "@ethersproject/contracts";
import axios from "axios";
import Web3 from "web3";
import { RPC_URL } from "src/constants";

export function sortAddress(add) {
  const sortAdd = `${add && add.slice(0, 6)}...${
    add && add.slice(add.length - 4)
  }`;
  return sortAdd;
}

export function getSigner(library, account) {
  return library.getSigner(account).connectUnchecked();
}

export function getProviderOrSigner(library, account) {
  return account ? getSigner(library, account) : library;
}

export function getContract(address, ABI, library, account) {
  return new Contract(address, ABI, getProviderOrSigner(library, account));
}

export const axiosInstance = axios.create({
  baseURL: baseApiUrl,
  // timeout: 5000,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
    token: window.localStorage.getItem("creatturAccessToken"),
  },
});

export const getWeb3Provider = async () => {
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL);
  return httpProvider;
};

export const getWeb3Obj = async () => {
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL);
  const web3 = await new Web3(httpProvider);
  return web3;
};

export const getWeb3ContractObject = async (abi, contractAddress) => {
  const web3 = await getWeb3Obj();
  const contract = await new web3.eth.Contract(abi, contractAddress);
  return contract;
};
