import React from 'react';
import { Typography, Box, makeStyles, Grid } from '@material-ui/core';

import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  NftImg: {
    borderRadius: 10,
    display: 'block',
    miHeight: '300px',
    position: 'relative',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    '& img': {
      width: '100%',
      height: '100%',
    },
  },
  bottomblock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
  },
  bottomTop: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    margin: '10px 0 0',
  },
  playbutton: {
    position: 'absolute',
    bottom: 5,
    right: 10,
  },
}));

export default function CollectionCard(props) {
  const { type, data } = props;
  const classes = useStyles();

  return (
    <Box
      className='bgCardcolor'
      style={{ borderRadius: 1, margin: '5px 10px' }}
    >
      <Box display='flex'>
        <Box
          className='collectionSet collectionSlide'
          style={{ width: '100%' }}
        >
          <Box className={classes.NftImg}>
            <img src={'images/collections/3.png'} width='100%' alt='' />
            <Box>
              <Box class='seller'>
                <Box class='seller-thumb avatar-lg'>
                  <img
                    class='rounded-circle'
                    src={
                      data.collectionImage
                        ? data.collectionImage
                        : '/images/userPRofile.png'
                    }
                    alt=''
                  />
                  <img
                    src='images/collections/check.png'
                    className='check_icon2'
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Link to='/collection'>
            <Box p={3} style={{ padding: '55px 17px 25px' }}>
              <Grid
                container
                direction='column'
                justify='center'
                alignItems='center'
                spacing={1}
              >
                <Grid item>
                  <Typography variant='h5' style={{ color: '#000' }}>
                    {data.name}
                  </Typography>
                </Grid>
                {type == 'user' ? (
                  ' '
                ) : (
                  <Grid item>
                    <Typography>{data.symbol}</Typography>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Link>
        </Box>
      </Box>
    </Box>
  );
}
