let baseurl = "https://node-nft-xavier.mobiloitte.com";

let user = `${baseurl}/api/v1/user`;
let nft = `${baseurl}/api/v1/nft`;
let admin = `${baseurl}/api/v1/admin`;
export const socketURL = "wss://node-nft-xavier.mobiloitte.com";
const url = `${baseurl}/api/v1`;

const apiConfig = {
  //ADMIN

  adminProfile: `${admin}/adminProfile`,
  blockUnblockNft: `${admin}/blockUnblockNft`,
  blockUnblockOrder: `${admin}/blockUnblockOrder`,
  blockUnblockUser: `${admin}/blockUnblockUser`,
  reportsList: `${admin}/reportsList`,
  listArtist: `${admin}/listArtist`,
  listUser: `${admin}/listUser`,
  addCategory: `${admin}/addCategory`,
  listCategory: `${admin}/listCategory`,
  addFees: `${admin}/addFees`,
  editFees: `${admin}/editFees`,
  viewFees: `${admin}/viewFees`,
  addPromoteNft: `${admin}/addPromoteNft`,
  listPromoteNft: `${admin}/listPromoteNft`,
  listPromoteOrder: `${admin}/listPromoteOrder`,
  addPromoteOrder: `${admin}/addPromoteOrder`,
  reportsOrderList: `${admin}/reportsOrderList`,
  listOrder: `${admin}/listOrder`,
  removeFromReportOrder: `${admin}/removeFromReportOrder`,
  unReportsOrderList: `${admin}/unReportsOrderList`,
  addSubAdmin: `${admin}/addSubAdmin`,
  subAdminList: `${admin}/subAdminList`,
  updateAdminProfile: `${admin}/updateAdminProfile`,
  editSubAdmin: `${admin}/editSubAdmin`,

  readNotification: `${url}/notification/readNotification`,

  //USER
  connectWallet: `${user}/connectWallet`,
  profile: `${user}/profile`,
  updateProfile: `${user}/updateProfile`,
  createCollection: `${user}/createCollection`,
  listCollection: `${user}/listCollection`,
  myCollection: `${user}/myCollection`,
  userList: `${user}/userList`,
  allNftList: `${user}/allNftList`,
  following: `${user}/following`,
  createReports: `${user}/createReports`,

  //NFT
  uploadNFT: `${nft}/uploadNFT`,
  ipfsUpload: `${nft}/ipfsUpload`,
  addNft: `${nft}/addNft`,
  placeOrder: `${nft}/placeOrder`,
  orderlist: `${nft}/orderlist`,
  allOrderList: `${nft}/allOrderList`,
  allNftList: `${nft}/allNftList`,
  likeDisLikeNft: `${nft}/likeDisLikeNft`,
  placeOrderlistById: `${nft}/placeOrderlistById`,
  sellOrder: `${nft}/sellOrder`,
  placeBid: `${nft}/placeBid`,
  profileUser: `${nft}/profileUser`,
  userFollowingCount: `${nft}/userFollowingCount`,
  userFollowerCount: `${nft}/userFollowerCount`,
  userLikesCount: `${nft}/userLikesCount`,
  userCreatedCount: `${nft}/userCreatedCount`,
  userOwendCount: `${nft}/userOwendCount`,
  userOnSaleCount: `${nft}/userOnSaleCount`,
  followingUserOrderList: `${nft}/followingUserOrderList`,
  allbidList: `${nft}/allbidList`,
  topBuyerList: `${nft}/topBuyerList`,
  topSalerList: `${nft}/topSalerList`,
  listPromoteNftUser: `${nft}/listPromoteNftUser`,
  showActivity: `${nft}/showActivity`,
};

export default apiConfig;
