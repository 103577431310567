import React, { useEffect, useState } from 'react';
import { Typography, Box, makeStyles, Grid } from '@material-ui/core';
import NFTCard from 'src/component/NFTCard';
import { Link } from 'react-router-dom';
import { axiosInstance } from '../../../utils/index';
import { toast } from 'react-toastify';
import OrderNftCard from 'src/component/OrderNftCard';

export default function Users(props) {
  const { type, data, OwnedList, callBackFun, userID } = props;
  const [walletdetails, setWalletdetails] = useState([]);
  useEffect(() => {
    axiosInstance({ method: 'GET', url: '/admin/listNft', params: {} })
      .then((res) => {
        setWalletdetails(res.data.result.docs);
      })
      .catch((err) => {
        toast.error(err.response.data.responseMessage);
      });
  }, []);
  return (
    <Box>
      <Grid container>
        {OwnedList.map((data, i) => {
          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              key={i}
              className='walletSet mb-20'
            >
              <OrderNftCard
                callBackFun={() => callBackFun(userID)}
                data={data}
                type='timing'
                index={i}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
