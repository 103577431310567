import React from 'react';
import { Typography, Box, makeStyles, Grid } from '@material-ui/core';
import NFTCard from 'src/component/NFTCard';
import { Link } from 'react-router-dom';
import OrderNftCard from 'src/component/OrderNftCard';

export default function Items(props) {
  const { type, data, orderList } = props;

  return (
    <Box>
      <Grid container>
        {orderList.map((data, i) => {
          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              key={i}
              className='walletSet mb-20'
            >
              <OrderNftCard data={data} type='timing' index={i} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
