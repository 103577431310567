import React, { useEffect, useState } from "react";
import { Typography, Box, makeStyles, Grid, Button } from "@material-ui/core";
import MyActivityCard, {
  BidCard,
  FolloWUnfollowCard,
  LikeDislikeCard,
} from "src/component/MyActivityCard";
import Filter from "src/component/Filter";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../../utils/index";
import { toast } from "react-toastify";
export default function Users(props) {
  const {
    type,
    data,
    activityList,
    userID,
    callBackFun,
    selectedFilter,
    setSelectedFilter,
    allListPageNumber,
    maxPages,
  } = props;
  const [walletdetails, setWalletdetails] = useState([]);
  useEffect(() => {
    axiosInstance({ method: "GET", url: "/admin/listNft", params: {} })
      .then((res) => {
        setWalletdetails(res.data.result.docs);
      })
      .catch((err) => {
        toast.error(err.response.data.responseMessage);
      });
  }, []);
  return (
    <Box>
      <Grid container>
        <Grid item xs={12} md={12} lg={7}>
          <Grid container>
            <All dataToDisplay={activityList} />
            {allListPageNumber < maxPages && (
              <Box textAlign='center' mb={2}>
                <Button
                  variant='contained'
                  size='large'
                  color='secondary'
                  onClick={async () => {
                    callBackFun(userID, true);
                  }}
                >
                  LOAD MORE
                  {/* {isUpdating && <ButtonCircularProgress />} */}
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={1}></Grid>
        <Grid item xs={12} md={12} lg={4}>
          <Filter
            selectedFilter={selectedFilter}
            setSelectedFilter={(data) => setSelectedFilter(data)}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export function All(props) {
  const { type, data, dataToDisplay } = props;

  return (
    <Box>
      <Grid container>
        {dataToDisplay.map((data, i) => {
          return (
            <Grid item xs={12} md={12} key={i} className='walletSet mb-20'>
              {(data.type === "PLACENFT" ||
                data.type === "SALEORDER" ||
                data.type === "NEWNFT" ||
                data.type === "SALENFT") && (
                <MyActivityCard data={data} type='timing' index={i} />
              )}
              {data.type === "BIDPLACE" && (
                <BidCard data={data} type='timing' index={i} />
              )}
              {(data.type === "FOLLOW" || data.type === "UNFOLLOW") && (
                <FolloWUnfollowCard data={data} type='timing' index={i} />
              )}
              {(data.type === "LIKE" || data.type === "DISLIKE") && (
                <LikeDislikeCard data={data} type='timing' index={i} />
              )}
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
