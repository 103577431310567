import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import HomeLayout from "src/layouts/HomeLayout";
import SearchComponent from "src/views/pages/Search/Index";
export const routes = [
  {
    exact: true,
    guard: true,
    path: "/",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Home")),
  },
  {
    exact: true,
    guard: true,
    path: "/artists-list",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ArtistList/Index")),
  },
  {
    exact: true,
    guard: true,
    path: "/nft-list",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/OrderNFTList/index")),
  },
  {
    exact: true,
    guard: true,
    path: "/about",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/About/AboutUs")),
  },

  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },

  {
    exact: true,
    path: "/createLogin",
    component: lazy(() => import("src/views/Admin/CreateLogin")),
  },
  {
    exact: true,
    guard: true,
    path: "/marketPlacefree",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/Admin/MarketPlace")),
  },
  {
    exact: true,
    guard: true,
    path: "/reportListing",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/Admin/ReportListing")),
  },
  {
    exact: true,
    guard: true,
    path: "/commissionFree",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/Admin/CommissionFree")),
  },
  {
    exact: true,
    guard: true,
    path: "/sub-admin",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/Admin/SubAdminDetails")),
  },
  {
    exact: true,
    guard: true,
    path: "/sub-admin-profil",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/Admin/SubAdmin")),
  },
  {
    exact: true,
    guard: true,
    path: "/reportDetail",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/Admin/ReportDetail")),
  },
  {
    exact: true,
    guard: true,
    path: "/adminProfile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/Admin/AdminProfile")),
  },
  {
    exact: true,
    guard: true,
    path: "/editProfile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/Admin/EditProfile")),
  },
  {
    exact: true,
    path: "/loginPage",
    component: lazy(() => import("src/views/Admin/LoginPage")),
  },
  {
    exact: true,
    path: "/forgotPage",
    component: lazy(() => import("src/views/Admin/ForgotPage")),
  },

  {
    exact: true,
    guard: true,
    path: "/Search",
    layout: HomeLayout,
    component: SearchComponent,
  },
  {
    exact: true,
    guard: true,
    path: "/detailsnftCard",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/NftDetails/DetailsnftCard")),
  },
  {
    exact: true,
    guard: true,
    path: "/blockNft",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/NftDetails/BlockNft")),
  },

  {
    exact: true,
    guard: true,
    path: "/following",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Search/Following")),
  },
  {
    exact: true,
    guard: true,
    path: "/activity",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Activity/index")),
  },

  {
    exact: true,
    path: "/create",
    guard: true,
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Create-NFT/Create")),
  },
  {
    exact: true,
    path: "/create-nft",
    guard: true,
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Create-NFT/CreateNFT")),
  },
  {
    exact: true,
    path: "/resell-nft",
    guard: true,
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Create-NFT/ResellNFT")),
  },
  {
    exact: true,
    guard: true,
    path: "/user",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/UsersView/UsersView")),
  },
  {
    exact: true,
    guard: true,
    path: "/profile",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Profile/Profile")),
  },
  {
    exact: true,
    guard: true,
    path: "/edit-profile",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Profile/EditProfile")),
  },
  {
    exact: true,
    guard: true,
    path: "/wallet",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Wallet/index")),
  },
  {
    exact: true,
    guard: true,
    path: "/fungy-token",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/FungyToken/Index")),
  },
  {
    exact: true,
    guard: true,
    path: "/faq",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/FAQ")),
  },
  {
    exact: true,
    guard: true,
    path: "/notification",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Notification")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
