import React from "react";
import { Typography, Box, makeStyles, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { FaEllipsisV } from "react-icons/fa";
import { FaHeart } from "react-icons/fa";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { axiosInstance } from "../utils/index";
const useStyles = makeStyles((theme) => ({
  NftImg: {
    borderRadius: 10,
    display: "block",
    miHeight: "300px",
    position: "relative",
  },
  bottomblock: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
  },
  bottomTop: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    margin: "10px 0 0",
  },
  playbutton: {
    position: "absolute",
    bottom: 5,
    right: 10,
  },
}));

export default function NFTCard(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const blockNft = (_id) => {
    axiosInstance({
      method: "PUT",
      url: "/admin/blockUnblockOrder",
      data: { _id },
    })
      .then((res) => console.log(res.data))
      .catch((err) => console.log(err.response.data.responseMessage));
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const { type, data } = props;
  const classes = useStyles();

  return (
    <Box className='CardBox'>
      <Box className='collectionSet' style={{ padding: "20px" }}>
        <Box className='card_heading'>
          <Box className='card_text'>
            <Box className='token_box'>
              <Box className='Token_img'>
                <img src='images/token/1.png' alt='' />
              </Box>
              <Box className='Token_img'>
                <img src='images/token/2.png' alt='' />
                <img
                  src='images/token/check.png'
                  className='check_icon'
                  alt=''
                />
              </Box>
            </Box>
            <Typography variant='h6'>{data.tokenName}</Typography>
            {/* <Typography variant="h6">{data.name}</Typography> */}
          </Box>

          <Typography variant='body1' onClick={handleClick}>
            <FaEllipsisV />
          </Typography>
          <Menu
            id='simple-menu'
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => blockNft(data._id)}>Block NFT</MenuItem>
            {/* <MenuItem onClick={handleClose}>Report</MenuItem> */}
          </Menu>
        </Box>
        <Box className={classes.NftImg}>
          {/* <Link to="/nft-details"> */}
          <img
            src={data.uploadFile}
            // src={data.img}
            width='100%'
            alt=''
            style={{ borderRadius: "5px" }}
          />
          {/* </Link> */}
          {/* <Box className={classes.playbutton}>
            <Avatar style={{ backgroundColor: "#1ed760", cursor: "pointer" }}>
              <PlayArrowIcon />
            </Avatar>
          </Box> */}
        </Box>
        {type == "timing" ? (
          ""
        ) : (
          <Box className='timing'>
            <Typography variant='body2' component='label'>
              04h
            </Typography>
            <Typography variant='body2' component='label'>
              31m
            </Typography>
            <Typography variant='body2' component='label'>
              10s
            </Typography>
            <Typography variant='body2' component='label'>
              left
            </Typography>
            <img src='images/icon.png' alt='' />
          </Box>
        )}

        <Box mt={2}>
          <Grid container justify='space-between'>
            <Grid item className='NFTDetailsBox'></Grid>
            <Box className='FooterData'>
              <Box>
                {/* <Typography variant="h6">Highest bid 1/1</Typography> */}

                <Typography variant='body2'>0.053 wETH</Typography>
              </Box>
              <Box className='d-flex justifyEnd text-black' pt={0}>
                <Typography className='like_box'>
                  {" "}
                  <FaHeart style={{ fontSize: "16px", color: "#231F20" }} />
                </Typography>
                <span
                  style={{
                    marginLeft: "10px",
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#3D3D3D",
                  }}
                >
                  {data.like?.length || 0}
                </span>
              </Box>
            </Box>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
